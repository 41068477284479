import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import SocialProfile from "components/SocialProfile/SocialProfile"
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoLinkedin,
} from "react-icons/io"
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
} from "./style"

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: "https://www.facebook.com/redqinc/",
    tooltip: "Facebook",
  },
  {
    icon: <IoLogoInstagram />,
    url: "https://www.instagram.com/redqinc/",
    tooltip: "Instagram",
  },
  {
    icon: <IoLogoTwitter />,
    url: "https://twitter.com/redqinc",
    tooltip: "Twitter",
  },
  {
    icon: <IoLogoLinkedin />,
    url: "https://www.linkedin.com/company/redqinc/",
    tooltip: "Linked In",
  },
]

interface AboutProps { }

const About: React.FunctionComponent<AboutProps> = props => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/about.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1770, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `)

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h2>About Sleepy Roo</h2>
        <p>
          Sleepy Roo is the website by Melbourne aspiring baby sleep consultant Anushka Britto.
        </p>
      </AboutPageTitle>

      <AboutImage>
        <Image fluid={Data.avatar.childImageSharp.fluid} alt="author" />
      </AboutImage>

      <AboutDetails>
        <h2>Hey, I'm Anushka!</h2>
        <p>
          Whether you have children or are thinking about having them, having children is widely considered to be life changing. Their tiny pitter-patters bring joy, laughs beautiful memories but also sadly, sleepless nights.
        </p>

        <p>
          The impact of this on a child’s development and the mental health of exhausted parents cannot be quantified but some of the effects are:
        <ul>
            <li>Suboptimal ante-natal growth</li>
            <li>Infant and Toddler difficulty to adjust to routines</li>
            <li>Poor performance at work due to sleep deprivation</li>
            <li>Stress on parents relationship</li>
          </ul>
        </p>

        <p>
          If you are struggling or simply want to establish good routines and sleeping habits from the very start, reach out to a sleep consultant and request a free consultation to find out more.
        </p>

        <p>
          This website documents my journey towards becoming a fully accredited Sleep Consultant and research on aspects of parenting, nutrition, and health covering pre-conception, pre-natal and ante-natal care.
        </p>

        {/* <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles> */}
      </AboutDetails>
    </AboutWrapper>
  )
}

export default About
